import React from "react"
import Container from "../components/Common/Layout/Container"
import Header from "../components/Common/Header"
import Footer from "../components/Common/Footer"
import SEO from "../components/Common/Seo"
import Contact from "../components/Contact"
import Theme from "../components/Theme"

const ContactUsPage = () => (
  <Theme>
	<Header />
	<Container>
		<SEO title="Contact us" />
		<Contact />
	</Container>
	<Footer />
  </Theme>
)

export default ContactUsPage
