import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Card from "../UI/Card"
import LinkCard from "../UI/LinkCard"
import HeadingBlock from "../UI/HeadingBlock"

Contact.propTypes = {
	className: PropTypes.string.isRequired,
}

function Contact({ className }) {

	return (
		<div className={className}>
			<HeadingBlock>
				<h1>Contact us</h1>
				<div className="strap-line">
					Call or email us to discuss how we can help your business
				</div>
			</HeadingBlock>

			<div className="contact-blocks">
				<Card accent className="general-enquiries-contact">
					<h2>General Enquiries</h2>
					<div className="contact-info">
						<LinkCard to="tel:00441484686560" className="accent" icon="phone">0044 1484 686560</LinkCard>
						<LinkCard to="mailto:hello@zenpharm.co.uk" icon="mail">hello@zenpharm.co.uk</LinkCard>
					</div>
				</Card>

				<Card>
					<h2>Kevin Nicholls</h2>
					
					<div className="main">
						<div className="contact-info">
							<LinkCard to="tel:00447767306983" icon="phone">0044 7767 306983</LinkCard>
							<LinkCard to="mailto:kevin@zenpharm.co.uk" icon="mail">kevin@zenpharm.co.uk</LinkCard>
							<LinkCard to="https://linkedin.com/in/kevin-nicholls-6139364" icon="linkedin">Kevin's Linkedin</LinkCard>
						</div>
						
						<div className="skillset-list">
							<h3>Specialties:</h3>
							<ul>
								<li>QA Consultancy</li>
								<li>Technical</li>
								<li>Auditing</li>
								<li>Licenses & Regulatory</li>
							</ul>
						</div>
					</div>
				</Card>

				<Card>
					<h2>Patricia Nicholls</h2>
					
					<div className="main">
						<div className="contact-info">
							<LinkCard to="tel:00447971539589" icon="phone">0044 7971 539589</LinkCard>
							<LinkCard to="mailto:patricia@zenpharm.co.uk" icon="mail">patricia@zenpharm.co.uk</LinkCard>
							<LinkCard to="https://linkedin.com/in/patricia-nicholls-36616690" icon="linkedin">Patricia's Linkedin</LinkCard>
						</div>

						<div className="skillset-list">
							<h3>Specialties:</h3>
							<ul>
								<li>QP & RP Services</li>
								<li>QA Consultancy</li>
								<li>Auditing</li>
								<li>Training</li>
							</ul>
						</div>
					</div>
				</Card>
			</div>
		</div>
	)
}
export default styled(Contact)`${({ theme })=>css`
	
	${Card} {
		max-width: 800px;
	}
	
	${LinkCard} {
		width: 100%;
		margin-bottom: 8px;
	}

	h2 {
		margin-top: 0;
	}

	.skillset-list {
		margin-top: 24px;
		margin-bottom: 32px;

		ul {
			list-style: square;
			padding-left: 24px;
			
			> li {
				margin: 8px 0;
			}
		}
	}

	${Card}.general-enquiries-contact {
		${LinkCard} {
			background: ${theme.c.focusTeal};

			&.accent {
				background: ${theme.c.yellow};
				color: ${theme.c.clayText};
			}
		}
	}

	@media (min-width: ${theme.bp.md}) {
		${Card} .main {
			display: flex;
			align-items: flex-start;

			.contact-info {
				flex-basis: 50%;
				max-width: 296px;
			}

			.skillset-list {
				flex-basis: 50%;
				margin-top: 0;
				margin-left: 32px;
				
				ul > li {
					font-size: 20px;
					margin-bottom: 16px;
				}
			}
		}
	}

	@media (min-width: ${theme.bp.md}) {
		${Card} {
			padding: 48px;

			${LinkCard} {
				padding: 24px;
			}
		}
	}

	@media (min-width: ${theme.bp.lg}) {
		${Card}.general-enquiries-contact .main {
			.contact-info {
				display: block;

				${LinkCard} {
					margin-left: 0;
					margin-right: 0;

				}
			}
		}

		${Card} .main .contact-info {
			max-width: 100%;
			flex-basis: 100%;
		}
	}

	@media (min-width: ${theme.bp.contact}) {

		.contact-blocks {
			display: flex;
			align-items: flex-start;

			${Card} {
				flex-basis: 33%;
				padding: 48px;
				margin-top: 0;
				margin-bottom: 0;
				margin-left: 24px;
				margin-right: 24px;
				&:first-child {
					margin-left: 48px;
				}
				&:last-child {
					margin-right: 48px;
				}

				.main {
					display: block;
				}

				.skillset-list {
					margin-left: 0;
					margin-top: 32px;
					
					ul {
						
						> li {
							font-size: 20px;
							margin-bottom: 8px;
						}
					}
				}
			}
		}
	}

	@media (min-width: ${theme.bp.md}) {
		${Card}.general-enquiries-contact {
			.contact-info {
				display: flex;

				${LinkCard} {
					margin: 0 16px;
					&:first-child {
						margin-left: 0;
					}
					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}

	@media (min-width: ${theme.bp.contact}) {
		
		
		${Card} {
			.contact-info {
				${LinkCard} {
					margin-bottom: 16px;
				}
			}
		}
		
		${Card}.general-enquiries-contact {
			.contact-info {
				display: block;

				${LinkCard} {
					margin: 0;
					margin-bottom: 20px;
					&:last-child {
						margin-bottom: 0;
					}
					
				}
			}
		}
	}
`}`